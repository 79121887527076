import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if (!this.heartbeatUrl()) return;

    this.initiateHeartbeat();
  }

  initiateHeartbeat() {
    // Delay in case we reloaded the page after sending a heartbeat
    const initialDelay = this.delayForHeartbeat();

    setTimeout(() => {
      this.sendHeartbeat();

      setInterval(this.sendHeartbeat, 300000);
    }, initialDelay);
  }

  delayForHeartbeat() {
    const lastHeartbeat = localStorage.getItem('heartbeat-timestamp');
    if (!lastHeartbeat) return 0;

    const timeSinceHeartbeat = new Date() - new Date(lastHeartbeat);
    return Math.max(300000 - timeSinceHeartbeat, 0);
  }

  sendHeartbeat() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET',  this.heartbeatUrl(), true);
    xhr.withCredentials = true;

    xhr.onerror = function() { console.error('SSO Heartbeat Failed.'); };

    xhr.onload = function() {
      localStorage.setItem('heartbeat-timestamp', new Date());
    };

    xhr.send();
  }

  heartbeatUrl() {
    return document.body.dataset.ssoHeartbeatUrl;
  }
}
